.marking-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
  font-family: Arial, sans-serif;
}

.question-block {
  margin-bottom: 30px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.question-block h2 {
  margin-bottom: 10px;
  color: #333;
}

.question-block p {
  margin-bottom: 10px;
  color: #555;
}

.answer-input {
  width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  resize: vertical;
}

.submit-button {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:disabled {
  background-color: #94d3a2;
  cursor: not-allowed;
}

.error-message {
  color: red;
  margin-top: 15px;
}

.results-section {
  margin-top: 40px;
}

.result-block {
  margin-bottom: 25px;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 5px;
}

.result-block h3 {
  margin-bottom: 10px;
  color: #333;
}

.feedback-toggle-button {
  padding: 5px 10px;
  font-size: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.feedback-toggle-button:hover {
  background-color: #0056b3;
}

.feedback-text {
  margin-top: 10px;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 4px;
  color: #555;
}
