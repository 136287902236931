* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  line-height: 1.6;
  background-color: #f5f5f5;
  color: #333;
}

.container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.content {
  max-width: 600px;
  width: 100%;
  text-align: center;
  background-color: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.profile-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 1.5rem;
  object-fit: cover;
  border: 3px solid #f5f5f5;
}

h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: #2d3748;
}

h2 {
  font-size: 1.25rem;
  color: #718096;
  margin-bottom: 1.5rem;
  font-weight: 500;
}

.bio {
  margin-bottom: 2rem;
  padding: 0 1rem;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: 1.75rem;
}

.social-links a {
  color: #4a5568;
  transition: color 0.2s ease-in-out;
}

.social-links a:hover {
  color: #2b6cb0;
}

/* Responsive Design */
@media (max-width: 480px) {
  .container {
    padding: 1rem;
  }

  .content {
    padding: 1.5rem;
  }

  .profile-image {
    width: 150px;
    height: 150px;
  }

  h1 {
    font-size: 1.75rem;
  }

  h2 {
    font-size: 1.1rem;
  }

  .social-links {
    font-size: 1.5rem;
    gap: 1rem;
  }
}
